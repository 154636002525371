import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="categories"
export default class extends Controller {
  static targets = [
    "ancestry",
    "category1",
    "category2",
    "category3",
    "categoryId",
  ];
  static values = {
    formView: Boolean,
    firstChildIndex: Number,
    secondChildIndex: Number,
    firstLabel: String,
    secondLabel: String,
  };

  connect() {
    Turbo.clearCache();

    const path_ids = this.ancestryTarget.value.split(",");
    // console.log(path_ids);

    this.resetCategories(path_ids);

    fetch(`/admin/categories/lookup_list.json`)
      .then((response) => response.json())
      .then((categories1) => {
        categories1.forEach((root) => {
          // console.log(root.id, path_ids);
          $(this.category1Target).append(
            `<option value="${root.id}" ${
              root.id === parseInt(path_ids[0]) ? "selected" : ""
            }>${root.name}</option>`
          );
        });
        return path_ids[0];
      })
      .then((data) => {
        if (data) {
          fetch(`/admin/categories/lookup_list.json?id=${data}`)
            .then((response) => response.json())
            .then((categories2) => {
              categories2.forEach((category2) => {
                // console.log(root.id, path_ids);
                $(this.category2Target).append(
                  `<option value="${category2.id}" ${
                    category2.id === parseInt(path_ids[1]) ? "selected" : ""
                  }>${category2.name}</option>`
                );
              });
              return path_ids[1];
            })
            .then((data) => {
              if (data) {
                fetch(`/admin/categories/lookup_list.json?id=${data}`)
                  .then((response) => response.json())
                  .then((categories3) => {
                    categories3.forEach((category3) => {
                      // console.log(root.id, path_ids);
                      $(this.category3Target).append(
                        `<option value="${category3.id}" ${
                          category3.id === parseInt(path_ids[2])
                            ? "selected"
                            : ""
                        }>${category3.name}</option>`
                      );
                    });
                    return path_ids[2];
                  })
                  .catch((error) => {
                    console.log(
                      "There has been a problem with your fetch operation:",
                      error
                    );
                  });
              }
            })
            .catch((error) => {
              console.log(
                "There has been a problem with your fetch operation:",
                error
              );
            });
        }
      })
      .catch((error) => {
        console.log(
          "There has been a problem with your fetch operation:",
          error
        );
      });
  }

  selectLeaf(id) {
    if (id != "") {
      fetch(`/admin/categories/${id}/lookup.json`)
        .then((response) => response.json())
        .then((data) => {
          this.categoryIdTarget.value = data.path_ids.slice(-1)[0];
          this.ancestryTarget.value = data.path_ids;
        });
    } else {
      const pathIds = this.ancestryTarget.value.split(",");
      this.categoryIdTarget.value = pathIds.slice(-2)[0] || "";
      this.ancestryTarget.value = pathIds.slice(0, -1).join(",") || "";
    }
  }

  handleSelectLeaf(e) {
    this.selectLeaf(e.target.value);
  }

  selectChange(e) {
    const id = e.target.value;
    // console.log('selectChange method called...', id)

    const categories = [
      this.ancestryTarget,
      this.category1Target,
      this.category2Target,
      this.category3Target,
    ];

    // empty options
    $(categories[e.target.dataset.firstChildIndex])
      .empty()
      .append(`<option value="">${e.target.dataset.firstLabel}</option>`);
    $(categories[e.target.dataset.secondChildIndex])
      .empty()
      .append(`<option value="">${e.target.dataset.secondLabel}</option>`);

    let fetch_url;
    if (id == "") {
      $.get(`/admin/categories.js`);
    } else {
      fetch_url = `/admin/categories/${id}/children.json`;
      // console.log(fetch_url);
      $.get(`/admin/categories/${id}/children.js`);

      fetch(fetch_url)
        .then((response) => response.json())
        .then((data) => {
          // console.log('data', data);
          data.forEach((category) => {
            // console.log(category.id, parseInt(id));
            // console.log('this.firstChildIndexValue', this.firstChildIndexValue)
            $(categories[e.target.dataset.firstChildIndex]).append(
              `<option value="${category.id}">${category.name}</option>`
            );
          });
        });
    }
  }

  handleSelectChange(e) {
    // console.log('select event called...');
    // console.log('e.target.dataset.firstChildIndex', e.target.dataset.firstChildIndex)
    this.selectChange(e);
    // if (this.hasFormViewValue && this.formViewValue) {
    this.selectLeaf(e.target.value);
    // }
  }

  resetCategories(path_ids) {
    $(this.category1Target).empty();
    $(this.category1Target).append(
      `<option value="" ${
        parseInt(path_ids[0]) == "" ? "selected" : ""
      }>분야</option>`
    );
    $(this.category2Target).empty();
    $(this.category2Target).append(
      `<option value="" ${
        parseInt(path_ids[1]) == "" ? "selected" : ""
      }>대분야</option>`
    );
    $(this.category3Target).empty();
    $(this.category3Target).append(
      `<option value="" ${
        parseInt(path_ids[2]) == "" ? "selected" : ""
      }>중분류</option>`
    );
  }
}
